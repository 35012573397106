button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    background: none;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, 
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-weight: 500;
}

button:hover{
    text-decoration: underline white;
}

.buttonGroup{
    margin: 0.5rem;
}