.frame{
    border: 1px solid skyblue;
    padding: 2em;
    min-width: 200px;
}

.image{
    max-width: 80vw;
}

h3{
    font-size: 1.5rem;
}
